import React, { Component } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Apputveckling" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.svg"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">APPUTVECKLING</h2>
                  <p>Från idé till slutförd app</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <picture>
                            <source
                              className="w-100"
                              srcSet="/assets/images/service/ica-utveckling.webp"
                              type="image/webp"
                            />
                            <img
                              className="w-100"
                              src="/assets/images/service/ica-utveckling.jpg"
                              alt="Service Images"
                            />
                          </picture>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            De flesta har använt en app någon gång som är så
                            smidig och enkel att använda att det blir en del av
                            din vardag. Det är precis det vi jobbar hårt för att
                            få fram.
                          </p>
                          <p>
                            Det är ofta enkelt att dra felaktiga slutsatser om
                            hur en produkt borde fungera. Vi testar saker på
                            riktiga användare för att se vad som faktiskt är bra
                            och fungerar.
                          </p>
                          <h4 className="title">Vår process</h4>
                          <ul className="liststyle">
                            <li>Utkristallisera idén och nyttan med appen</li>
                            <li>Skissa fram flöden på Postit-lappar</li>
                            <li>
                              Bygga en första version snabbt och börja testa på
                              riktiga användare
                            </li>
                            <li>Förfina design och layout</li>
                            <li>
                              Bygga Appstore optimerad för sök och lansera
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Många konsultbolag överväger inte teknikval utan
                            väljer något som gör att det går snabbt för dem och
                            hög marginal. Alltså ingen omtanke gällande
                            slutanvändaren och om ni kommer få en nöjd,
                            återkommande kund.
                          </p>
                          <p>
                            Om du någon gång använt en app och blivit irriterad
                            över att den är långsam, crashar eller att det är
                            svårt att hitta till det du vill göra så förstår du
                            varför det är viktigt.
                          </p>
                          <h4 className="title">Våra tre gyllene regler</h4>
                          <ul className="liststyle">
                            <li>Tänk alltid på användaren av appen</li>
                            <li>
                              Testa alltid för att få reda på vad som fungerar
                              bäst
                            </li>
                            <li>Minimera laddtider och undvik crasher</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <picture>
                            <source
                              className="w-100"
                              srcSet="/assets/images/service/appstore-photo.webp"
                              type="image/webp"
                            />
                            <img
                              className="w-100"
                              src="/assets/images/service/appstore-photo.jpg"
                              alt="Service Images"
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
